var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSubmit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(1, "Name"),
                            "label-for": "Name",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.FormMSG(
                                2,
                                "Name or description..."
                              ),
                            },
                            model: {
                              value: _vm.material.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.material, "name", $$v)
                              },
                              expression: "material.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(3, "Unit"),
                            "label-for": "Unit",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              placeholder: _vm.FormMSG(4, "Choose an unit..."),
                              options: _vm.units,
                            },
                            on: { change: _vm.onSelectUnit },
                            model: {
                              value: _vm.material.unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.material, "unit", $$v)
                              },
                              expression: "material.unit",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.material.unit === 0
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(5, "Custom unit"),
                                "label-for": "CustomUnit",
                                "label-cols": 3,
                                horizontal: true,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    6,
                                    "Your custom unit..."
                                  ),
                                },
                                model: {
                                  value: _vm.material.customUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.material, "customUnit", $$v)
                                  },
                                  expression: "material.customUnit",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(8, "Unit price"),
                            "label-for": "UnitPrice",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              type: "number",
                              step: "0.01",
                              min: "0",
                              max: "100000000.00",
                            },
                            on: {
                              update: _vm.updateTotalPrice,
                              change: _vm.updateTotalPrice,
                            },
                            model: {
                              value: _vm.material.unitPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.material, "unitPrice", $$v)
                              },
                              expression: "material.unitPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(7, "Quantity"),
                            "label-for": "Quantity",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { type: "number", step: "0.1", min: "0" },
                            on: {
                              keyup: _vm.handleInputQuantity,
                              change: _vm.updateTotalPrice,
                            },
                            model: {
                              value: _vm.material.quantity,
                              callback: function ($$v) {
                                _vm.$set(_vm.material, "quantity", $$v)
                              },
                              expression: "material.quantity",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.FormMSG(9, "Total price"),
                            "label-for": "TotalPrice",
                            "label-cols": 3,
                            horizontal: true,
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              value: _vm.material.totalPrice,
                              type: "text",
                              placeholder: "0.00",
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4", offset: "8" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                variant: "primary",
                                size: "md",
                                block: "",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.FormMSG(10, "Save"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }